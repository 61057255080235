import FileSaver from 'file-saver';
import CountUp from 'react-countup';
import removeMd from 'remove-markdown';

import * as Session from './session';
import crypto from './crypto';
import moment from 'moment';
import dayjs from 'dayjs';
import JSZip from 'jszip';
import { EMPTY_PAGE_BEAN } from '@/constants/common';
import uuid from './uuid';

const sysTimer = {};

const Utils = {

  /** 按宽高缩略显示图片 */
  compressImage(url, width = 100) {
    // return url + '?x-oss-process=image/resize,w_' + width;
    return Utils.getImg(url, 0, { resize: `w_${width}` });
  },

  /** 按比例缩略显示图片 */
  thumbImage(url, rate = 50) {
    // return url + '?x-oss-process=image/resize,p_' + rate;
    return Utils.getImg(url, rate / 100);
  },
  /**
   * Flat Map 根据相应的字断
   * @param arr 源数组
   * @param step 层级
   * @param clear 是否删除原层级数据
   * @returns {[]|*[]}
   */
  flatMap(arr, step = 'children', clear = false) {
    const flatArr = [];
    const iterate = function(item) {
      flatArr.push(item);
      if(item[step]) {
        item[step].forEach(v => {
          iterate(v);
        });
        if(clear) {
          delete item[step];
        }
      }
    };
    if(!arr || !(arr instanceof Array)) {
      return [];
    } else {
      arr.forEach(iterate);
      return flatArr;
    }
  },
  encrypt(data, key = '50525c3fbe3b9218') {
    return crypto.aesEncrypt(data, key);
  },
  decrypt(data, key = '50525c3fbe3b9218') {
    return crypto.aesDecrypt(data, key);
  },
  isJSONString(string = '', useStrict = false) {
    if(typeof string === 'string') {
      try {
        JSON.parse(string);
        if(useStrict) {
          return (
            string.includes('{') && string.includes(':') && string.includes('}')
          );
        }
        return true;
      } catch(e) {
        return false;
      }
    }
    return false;
  },
  /**
   * unFlat Map 根据相应的字断
   * @param items 扁平数组
   * @param key 父类id key
   * @param parentId 父类id key
   * @param child 子类 key
   * @returns {[]}
   */
  unFlatMap(items, key = 'id', parentId = 'parentId', child = 'children') {
    const result = [];   // 存放结果集
    const itemMap = {};  //
    for(const item of items) {
      const id = item[key];
      const pid = item[parentId];

      if(!itemMap[id]) {
        itemMap[id] = { [child]: [] };
      }

      itemMap[id] = { ...item, [child]: itemMap[id][child] };

      const treeItem =  itemMap[id];

      if(pid === 0) {
        result.push(treeItem);
      } else {
        if(!itemMap[pid]) {
          itemMap[pid] = { [child]: [] };
        }
        itemMap[pid][child].push(treeItem);
      }
    }
    return result;
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return item ? { ...acc, ...{ [arr[0]]: arr[1] }} : acc;
    }, {});
  },

  handlerOptions(option, textKey, valueKey) {
    if(!option) throw new Error('option can not be undifend or null');
    const optionProps = {};
    if(typeof option === 'object') {
      optionProps.disabled = option.disabled || false;
      option = {
        [textKey]: option[textKey],
        [valueKey]: option[valueKey]
      };
    } else {
      option = { [textKey]: option, [valueKey]: option };
    }
    return { option, optionProps };
  },
  getFileExt(fileName = '') {
    const index = fileName.lastIndexOf('.');
    const regExp = new RegExp('^[A-Za-z0-9]+$');
    if(index > 0 && regExp.test(fileName.slice(index + 1))) {
      fileName = fileName.toLowerCase();
      return fileName.substring(index + 1);
    }
    return '';
  },
  // 导出文件
  exportFile(url = '', name = '') {
    if(!url) return;
    const format = Utils.getFileExt(url);
    return FileSaver.saveAs(url, `${`${name.replace('.' + format, '')}`.trim()}.${format}`);
  },
  /**
   * 更换直接下载地址
   * @param {string} url 源url
   */
  changeDownloadOrigin(url) {
    if(!url.includes('/oss/')) return url;
    const u = new URL(url);
    if(u.host.includes('raysgo.com')) {
      u.host = 'file-download.raysgo.com';
    } else if(u.host.includes('5rs.me')) {
      u.host = 'file-download.5rs.me';
    }
    return u.href;
  },
  // 单文件下载
  downloadSingleFile(url) {
    if(!url) return;
    url = Utils.changeDownloadOrigin(url);
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer'); // 安全性考虑，避免跨窗口的 JS 攻击
    link.setAttribute('download', '');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  /**
   * 打包下载资源
   * @param {*} zipName 压缩包名
   * @param {*} files 文件 { path： 路径, url: 文件数据 }
   */
  async exportZip(zipName, files = [], cb) {
    let zip = new JSZip();
    for(let i = 0; i < files.length; i++) {
      const file = files[i];
      const data = await fetch(file.url).then(res => res.arrayBuffer());
      zip.file(file.path, data);
    }
    const zipFile = await zip.generateAsync({ type: 'blob' });
    cb && cb();
    FileSaver.saveAs(zipFile, zipName + '.zip');

  },

  isNotEmpty(value) {
    switch (typeof value) {
      case 'undefined': {
        return false;
      }

      case 'string': {
        return value.length !== 0;
      }

      case 'object': {
        if(Array.isArray(value)) {
          return value.length !== 0;
        } else if(value === null) {
          return false;
        } else {
          return Object.keys(value).length !== 0;
        }
      }

      default: {
        return true;
      }
    }
  },

  //保留搜索页
  checkSearchOptions(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey));
    } else {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    }
  },

  //保留搜索页
  /**
   * 传了searchOptions就会存储，不传就是读取
   * @param searchOptions
   * @returns {any|void}
   */
  checkSearchOptions1(searchOptions) {
    const pathname = window.location.pathname;
    const sessionKey = `${pathname}-searchOptions`;
    if(searchOptions) {
      return Session.put(sessionKey, JSON.stringify(searchOptions));
    } else if(Session.get(sessionKey)) {
      return JSON.parse(Session.get(sessionKey || '{}'));
    }
  },

  searchAssign(origin = {}, values = {}, ...params) {
    let tempValue = Object.assign({}, values);
    if(params && params.length) {
      params.forEach((item) => {
        Object.assign(tempValue, item);
      });
    }
    if(Object.keys(tempValue).length) {
      for(let key in tempValue) {
        if(this.isNotEmpty(tempValue[key]) && this.isNotEmpty(String(tempValue[key]))) {
          origin[key] = tempValue[key];
        } else {
          delete origin[key];
        }
      }
    } else {
      for(let key in origin) {
        if(!this.isNotEmpty(origin[key])) {
          delete origin[key];
        }
      }
    }
    return origin;
  },

  /**
   * 先过滤对象中的空属性，再合并，使用方法类似于Object.assign，不对第一个参数做修改，而是将结果作为新的对象返回
   * @param args
   * @returns {{}}
   */
  filterMerge(...args) {
    return Object.assign.apply(null, args.map((object) => {
      return Object.keys(object).reduce((obj, key) => {
        const value = object[key];
        if(Utils.isNotEmpty(value) && value !== 'undefined') {
          obj[key] = value;
        }
        return obj;
      }, {});
    }));
  },

  json2params(json, slice = '&') {
    return Object.keys(json).reduce((acc, item) => {
      return String(acc) + item + '=' + json[item] + slice;
    }, '').slice(0, -1);
  },

  formatQuery2QueryStr(query = {}) {
    let queryStr = '';
    const filteredQuery = Utils.filterMerge(query);
    if(Utils.isNotEmpty(filteredQuery)) {
      queryStr = '?' + Utils.json2params(filteredQuery);
    }
    return queryStr;
  },

  /**
   * 链接拼参数
   * @param url 可以是带query的也可以是不带的
   * @param params 拼的参数对象
   * @returns {string}
   */
  padQuery(url = '', params = {}) {
    const [pathname, queryStr] = url.split('?');
    let tempQuery = Utils.filterMerge(Object.assign({}, Utils.params2json(queryStr), Utils.filterMerge(Object.assign({}, params))));
    let searchQuery = Utils.formatQuery2QueryStr(tempQuery);
    return `${pathname}${searchQuery}`;
  },
  // 权限管理处理数据信息
  handleDataInfo(data = [], type = 1) {
    let list = [];
    if(type === 1) {
      data.forEach(item => {
        const infoFirst = {};
        infoFirst.title = item.name;
        infoFirst.key = item.id;
        if(item.childList && item.childList.length > 0) {
          infoFirst.children = [];
          item.childList.forEach(v => {
            const infoTwo = {};
            infoTwo.title = v.name;
            infoTwo.key = v.id;
            infoFirst.children.push(infoTwo);
          });
        }
        list.push(infoFirst);
      });
    } else {
      data.forEach(item => {
        list.push(item.id);
        if(item.childList && item.childList.length > 0) {
          item.childList.forEach(v => {
            list.push(v.id);
          });
        }
      });
    }
    return list;
  },
  beautifyTime(dateTime) {
    let day = dayjs(dateTime);
    let today = dayjs().format('YYYY-MM-DD');
    const d = day.diff(today, 'day', true);
    let date = '';
    let time = day.format('HH:mm');
    if(d >= 0) {
      date = '今天';
    } else if(d >= -1) {
      date = '昨天';
    } else {
      date = day.format('MM/DD');
    }
    return date + ' ' + time;
  },
  getDate(time) {
    if(time && !(+time > 0)) {
      let [tDate, tTime] = time.split(' ');
      tDate = tDate.replace(/[^\d]/g, '/');
      time = tTime ? `${tDate} ${tTime}` : `${tDate}`;
    }
    const d = time ? new Date(time) : new Date();
    const M = d.getMonth() + 1;
    const D = d.getDate();
    const h = d.getHours();
    const m = d.getMinutes();
    const s = d.getSeconds();
    return {
      Y: d.getFullYear(),
      M: M < 10 ? '0' + M : M,
      W: d.getDay(), //周几
      D: D < 10 ? '0' + D : D,
      h: h < 10 ? '0' + h : h,
      min: m < 10 ? '0' + m : m,
      s: s < 10 ? '0' + s : s,
      ms: d.getMilliseconds()
    };
  },
  /**
   * byte 单位转 KB, M
   * @param {number} size byte value
   * @param {number} decimal decimal places 保留几位小数，默认2位（0.01KB）
   * @returns {string}
   */
  getSizeByByte(size, decimal = 2) {
    if(typeof size !== 'number') {
      size = Number(size);
      if(!size) return '';
    }
    const KBUNIT = 'KB', MBUNIT = 'M', UNITSIZE = 1024;
    let kb = size / UNITSIZE,
      mb = size / (UNITSIZE * UNITSIZE);
    const n = Math.pow(10, -decimal);
    return mb > n
      ? parseFloat(mb).toFixed(decimal) + MBUNIT
      : parseFloat(kb).toFixed(decimal) + KBUNIT;
    // return parseFloat(mb).toFixed(2) + MBUNIT;
  },
  /**
   * 三位数内的阿拉伯数字转换
   * @param num
   * @returns {string}
   */
  numberConvertToUppercase(num) {
    num = Number(num);
    let upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
    let length = String(num).length;
    if(length === 1) {
      return upperCaseNumber[num];
    } else if(length === 2) {
      if(num === 10) {
        return upperCaseNumber[num];
      } else if(num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
      }
    }
  },

  checkISBN10(code) {
    code = String(code).replace(/[-\s]/g, '');
    if(!/^\d{9}[\dxX]?$/.test(code)) {
      return '';
    }
    let i = 0,
      c = 0;
    for(; i < 9;) {
      c += code.charAt(i++) * i;
    }
    c %= 11;
    if(c === 10) {
      c = -1;
    }
    i = code.charAt(9);
    if(i === 'x' && c === -1) {
      return code;
    } else if(c !== -1 && Number(c) === Number(i)) {
      return code;
    } else {
      return '';
    }
  },

  checkISBN13(code) {
    code = String(code).replace(/[-\s]/g, '');
    if(!/^\d{12,13}$/.test(code)) {
      return '';
    }
    let i = 1,
      c = 0;
    for(; i < 12; i += 2) {
      c += Math.floor(code.charAt(i));
    }
    for(c *= 3, i = 0; i < 12; i += 2) {
      c += Math.floor(code.charAt(i));
    }
    c = (220 - c) % 10;
    return Number(c) === Number(code.charAt(12)) ? code : '';
  },

  checkISSN(code) {
    return Number(code) > 0 && code.length < 14 && code.length > 7;
  },

  checkCN(code) {
    code = String(code).replace(/[-\s]/g, '');
    if(code.indexOf('/') > -1) {
      code = code.split('/')[0];
    }
    if(code.length !== 6) {
      return '';
    }
    return code;
    //return code.charAt(0) === 'C' && code.charAt(1) === 'N' ? code : '';
  },

  // 配置二维码扫码链接
  getAppUrl(itemType, id, basePath, commonQuerys, sceneCode) {
    let middlePath = '';
    if(itemType === 'KK') {
      return `*kk${basePath}A${id}/questionnaire${commonQuerys}`;
    } else if(
      itemType === 'LIVE' ||
        itemType === 'LIVE_TIMETABLE' ||
        itemType === 'SCHEDULE'
    ) {
      // return `${basePath}application/${id}/live${commonQuerys}`;
      return `*live${basePath}A${id}/liveinfo${commonQuerys}`;
    } else if(itemType === 'ACTIVITY') {
      return `${basePath}application/${id}/propaganda${commonQuerys}`;
    } else if(itemType === 'NAIRE' || itemType === 'DA') {
      return `*qn${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'SPECIAL') {
      return `*st${basePath}A${id}/special${commonQuerys}`;
    } else if(itemType === 'VOTE') {
      return `*vt${basePath}A${id}/${Date.now()}/list${commonQuerys}`;
    } else if(itemType === 'BM') {
      return `*mt${basePath}A${id}/summary${commonQuerys}`;
    } else if(itemType === 'RECITE_WORD') {
      switch (sceneCode) {
        //消消乐
        case 'LINK':
          middlePath = 'blocks';
          break;
        default:
          middlePath = 'list';
      }
      return `*wd${basePath}A${id}/${middlePath}${commonQuerys}`;
    } else if(itemType === 'EF') {
      return `*tb${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'TUTOR') {
      return `*cc${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'ITEM_BANK') {
      return `*pretest${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'GROUP') {
      return `*group${basePath}A${id}/info${commonQuerys}`;
    } else if(itemType === 'ZSCORE') {
      return `*zscore${basePath}A${id}/search${commonQuerys}`;
    } else if(itemType === 'VOICE_MSG') {
      return `${basePath}application/${id}/voicemail${commonQuerys}`;
    } else if(itemType === 'BOOKCARD') {
      return `*card${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'INTERACT') {
      return `*interact${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'CLOCK') {
      return `*clock${basePath}A${id}/custom/sign${commonQuerys}`;
    } else if(itemType === 'AUDIO_MAGIC') {
      return `*magic${basePath}A${id}/box${commonQuerys}`;
    } else if(itemType === 'COURSE_WARE') {
      return `*cw${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'WORD_DICTATION') {
      return `*dictation${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'MATCH_LISTEN') {
      return `*mv${basePath}A${id}/listen${commonQuerys}`;
    } else if(itemType === 'ARTICLE_READING') {
      return `*ar${basePath}A${id}/reading${commonQuerys}`;
    } else if(itemType === 'ENGLISH_WALKMAN') {
      return `*wm${basePath}A${id}/player${commonQuerys}`;
    } else if(itemType === 'PBSTORY') {
      return `*pbstory${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'ORAL_EVALUATION') {
      return `*speak${basePath}A${id}/info${commonQuerys}`;
    } else if(itemType === 'TEST') {
      switch (sceneCode) {
        //属性测试
        case 'TEST_CHARACTER':
          middlePath = 'char';
          break;
          //知识测试
        case 'TEST_KNOWLEDGE':
          middlePath = 'know';
          break;
          //情感测试
        case 'TEST_EMOTION':
          middlePath = 'emotion';
          break;
        case 'TEST_ACTIVITY':
          middlePath = 'activity';
          break;
        case 'TEST_DIFFERENCE':
          middlePath = 'findDiff';
          break;
        case 'TEST_LOGIC':
          middlePath = 'gamebook';
          break;
        default:
          middlePath = 'char';
      }
      return `*tt${basePath}A${id}/${middlePath}/info${commonQuerys}`;
    } else if(itemType === 'WISH') {
      return `*wish${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'DRAW') {
      // 抽奖新类型
      let routeName = 'luckPage';
      if(sceneCode === 'TASK') {
        routeName = 'lucktask';
      }
      return `*luck${basePath}A${id}/${routeName}${commonQuerys}`;
    } else if(itemType === 'VIDEO') {
      return `${basePath}application/${id}/video${commonQuerys}`;
    } else if(itemType === 'EBOOK') {
      return `${basePath}application/${id}/ebook${commonQuerys}`;
    } else if(itemType === 'AUDIO') {
      switch (sceneCode) {
        // 绘本配乐
        case 'PAINTING':
          return `${basePath}application/${id}/audio/painting${commonQuerys}`;
          // 绘本配乐
        case 'LISTEN':
          return `${basePath}application/${id}/audio/listen${commonQuerys}`;
          // 绘本配乐
        case 'LESSON':
          return `${basePath}application/${id}/audio/lesson${commonQuerys}`;
        default:
          return `${basePath}application/${id}/audio${commonQuerys ? `${commonQuerys}&` : '?'}appType=${itemType}`;
      }
    } else if(itemType === 'TEACH_RESOURCE') {
      return `*tr${basePath}A${id}/packagelist${commonQuerys}`;
    } else if(itemType === 'TEST_PAPER') {
      return `${basePath}application/${id}/testpaper/paperList/${commonQuerys}`;
    } else if(itemType === 'STROKE_ORDER') {
      return `*pen${basePath}A${id}/list${commonQuerys}`;
    } else if(itemType === 'MEMBER') {
      return `*member${basePath}A${id}/info${commonQuerys}`;
    } else if(itemType === 'SUBJECTNOTE') {
      return `*subject${basePath}A${id}${commonQuerys}`;
    } else if(itemType === 'BOOK_CLICK') {
      return `*clickread${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'FLAG') {
      return `*flag${basePath}A${id}/entryFlag/start${commonQuerys}`;
    } else if(itemType === 'COLD_KNOWLEDGE') {
      return `*cold${basePath}A${id}/entryCold/start${commonQuerys}`;
    } else if(itemType === 'TICKET') {
      return `*aticket${basePath}A${id}/ticketHome${commonQuerys}`;
    } else if(itemType === 'FOREIGN_READ') {
      return `*getti${basePath}A${id}/gettiHome${commonQuerys}`;
    } else if(itemType === 'BOOK_ORDER') {
      return `${basePath}application/${id}/bookOrder${commonQuerys}`;
    } else if(itemType === 'WORD_LINK') {
      return `*eliminate${basePath}A${id}/home${commonQuerys}`;
    } else if(BAN_DU_APP.includes(itemType)) {
      return `/rayh5/applet?type=${itemType}`;
    } else if(itemType === 'LEARNING_REPORT') {
      return `/rayh5/learning/study${commonQuerys}`;
    } else if(itemType === 'MEMBER_ACTIVITY') {
      return `/rayh5/member/detail${commonQuerys ? commonQuerys + '&' : '?'}activityId=${id}`;
    } else if(itemType === 'JIGSAW') {
      return `*jigsaw${basePath}A${id}/${middlePath}home${commonQuerys}`;
    } else if(itemType === 'POSTGRADUATE_CALC') {
      return `*ky${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'GRAFFITI') {
      return `*graffiti${basePath}A${id}/${middlePath}home${commonQuerys}`;
    } else if(itemType === 'PIXEL_PAINTING') {
      return `*pixel${basePath}A${id}/${middlePath}home${commonQuerys}`;
    } else if(itemType === 'MOUNTING_MASTER') {
      return `*rahmen${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'EN_CH_DICTIONARY') {
      basePath = basePath.includes('W') ? '/W23' : '';
      return `*dictionary${basePath}/translation${commonQuerys}`;
    } else if(itemType === 'EN_WRITING_TEMPLATE') {
      basePath = basePath.includes('W') ? '/W23' : '';
      return `*write${basePath}/home${commonQuerys}`;
    } else if(itemType === 'CLASS_TOOL') {
      return `*classtool${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'INTELLECT_RECITE') {
      return `*recite${basePath}A${id}/main${commonQuerys}`;
    } else if(itemType === 'CALLIGRAPHY_COPY') {
      return `*penman${basePath}A${id}/home${commonQuerys}`;
    } else if(itemType === 'FUNNY_PRACTICE') {
      return `*practice${basePath}A${id}/main${commonQuerys}`;
    } else if(itemType === 'COLLAGE') {
      return `*collage${basePath}A${id}/${middlePath}home${commonQuerys}`;
    } else if(itemType === 'ONLINE_EXAM') {
      basePath = basePath.includes('W') ? '/W23' : '';
      return `*paperline${basePath}/center${commonQuerys}`;
    } else if(itemType === 'LISTENING_TRAINING') {
      basePath = basePath.includes('W') ? '/W23' : '';
      return `*listentrain${basePath}/home${commonQuerys}`;
    } else if(itemType === 'HONOR_MEDAL') {
      basePath = basePath.includes('W') ? '/W23' : '';
      return `*medal${basePath}/my${commonQuerys}`;
    } else {
      return `${basePath}application/${id}/display${commonQuerys ? `${commonQuerys}&` : '?'}appType=${itemType}`;
    }
  },
  getProductUrl(itemType, id, basePath, commonQuerys) {
    const newTypes = NEW_PRODUCT_TYPES.map(v => v.typeCode);
    if(itemType === 'PRETEST') {
      return `*pretest${basePath}P${id}/list${commonQuerys}`;
    } else if(['LIVE', 'LIVE_TIMETABLE', 'SCHEDULE'].includes(itemType)) {
      return Utils.padQuery(`*live${basePath}A${id}/liveinfo${commonQuerys}`, {
        proType: 'SCHEDULE'
      });
    } else if(itemType === 'QA') {
      return `${basePath}application/-1/foranswer/select/${id}${commonQuerys}`;
    } else if(itemType === 'SUBJECTNOTE') {
      return `*subject${basePath}P${id}${commonQuerys}`;
    } else if(itemType === 'ATTENDANCE_TASK') {
      return `*clock${basePath}P${id}${commonQuerys}`;
    } else if(itemType === 'VIDEO_SCHEDULE') {
      return `${basePath}product/display/${id}/video/preview${commonQuerys}`;
    } else if(itemType === 'AUDIO_SCHEDULE') {
      return `*audio${basePath}P${id}/lesson/home${commonQuerys}`;
    } else if(newTypes.includes(itemType)) {
      const item = NEW_PRODUCT_TYPES.find(v => v.typeCode === itemType);
      return `*${item.prefix}${basePath}P${id}/${item.suffix}${commonQuerys}`;
    } else {
      return `${basePath}product/display/${id}${commonQuerys}`;
    }
  },
  getResourceUrl(basePath, resourceId, commonQuerys) {
    return `${basePath}content/resource/${resourceId}${commonQuerys}`;
  },
  // 处理数据
  formatNumber(num, duration = 0, start = 0.8, fixedLength = 2, unit) {
    if(!num) {
      return '0';
    }
    let value = num || 0;
    if(num > 0 && num < 1e4) {
      value = <span className="formatNumber">
        <CountUp
          redraw={false}
          start={num * start}
          end={num}
          decimals={num % 1 === 0 ? 0 : fixedLength}
          duration={duration}
        />
        <span className="formatNumber-unit">{unit}</span>
      </span>;
    }
    if(num >= 1e4 && num < 1e8) {
      value = <span className="formatNumber">
        <CountUp
          redraw={false}
          start={(num / 1e4).toFixed(fixedLength) * start}
          end={(num / 1e4).toFixed(fixedLength)}
          decimals={fixedLength}
          duration={duration}
        />
        <span className="formatNumber-unit">{unit || '万'}</span></span>;
    } else if(num >= 1e8) {
      value = <span className="formatNumber">
        <CountUp
          redraw={false}
          start={(num / 1e8).toFixed(fixedLength) * start}
          end={(num / 1e8).toFixed(fixedLength)}
          decimals={fixedLength}
          duration={duration}
        />
        <span className="formatNumber-unit">{unit || '亿'}</span></span>;
    }
    return value;
  },
  formatNumber1(num, duration = 0, start = 0.8, fixedLength = 2, unit) {
    if(!num) {
      return '--';
    }
    let value = num || 0;
    // if(num >= 1e3 && num < 1e4) {
    //   value = <span className="formatNumber">{(num / 1e3).toFixed(fixedLength)}<span className="formatNumber-unit">K</span></span>;
    // } else
    if(num > 0 && num < 1e4) {
      value = <span className="formatNumber">
        <CountUp
          redraw={false}
          start={num * start}
          end={num}
          decimals={num % 1 === 0 ? 0 : fixedLength}
          duration={duration}
        />
        <span className="formatNumber-unit">{unit}</span>
      </span>;
    }
    if(num >= 1e4 && num < 1e8) {
      value = <span className="formatNumber">
        <CountUp
          redraw={false}
          start={(num / 1e4).toFixed(fixedLength) * start}
          end={(num / 1e4).toFixed(fixedLength)}
          decimals={fixedLength}
          duration={duration}
        />
        <span className="formatNumber-unit">{unit || 'W'}</span></span>;
    } else if(num >= 1e8) {
      value = <span className="formatNumber">
        <CountUp
          redraw={false}
          start={(num / 1e8).toFixed(fixedLength) * start}
          end={(num / 1e8).toFixed(fixedLength)}
          decimals={fixedLength}
          duration={duration}
        />
        <span className="formatNumber-unit">{unit || '亿'}</span></span>;
    }
    return value;
  },
  formatNumber3(num, fixedLength = 2) {
    if(Number.isNaN(+num)) {
      return num;
    }
    const isNegative = num < 0;
    let value = Math.abs(num) || 0;
    if(value >= 1e4 && value < 1e8) {
      value = `${isNegative ? '-' : ''}${(value / 1e4).toFixed(fixedLength)}W`;
    } else if(value >= 1e8) {
      value = `${isNegative ? '-' : ''}${(value / 1e8).toFixed(fixedLength)}亿`;
    } else {
      value = num;
    }
    return value;
  },
  formatTime(sd) {
    if(!sd) return '刚刚';
    const s = sd, m = Math.floor(s / 60),
      h = Math.floor(m / 60), d = Math.floor(h / 24), w = Math.floor(d / 7), month = Math.floor(d / 30), year = Math.floor(month / 12);
    if(s <= 0) {
      return '刚刚';
    }
    if(m < 1) {
      return `${s}秒前`;
    } else if(h < 1) {
      return `${m}分钟前`;
    } else if(d < 1) {
      return `${h}小时前`;
    } else if(d < 2) {
      return '昨天';
    } else if(w < 1) {
      return `${d}天前`;
    } else if(w < 10) {
      return `${w}周前`;
    } else if(month < 12) {
      return `${month}月前`;
    } else {
      return `${year}年前`;
    }
  },
  formatBeforeTime(time, after = '前') {
    const result = parseInt(time / 60);
    if(result === 0) {
      return '刚刚';
    } else if(result < 60) {
      return result + '分钟' + after;
    } else if(result < 60 * 24) {
      return parseInt(result / 60) + '小时' + after;
    } else if(result < 60 * 24 * 7) {
      return parseInt(result / 60 / 24) + '天' + after;
    } else if(result < 60 * 24 * 30) {
      return parseInt(result / 60 / 24 / 7) + '周' + after;
    } else if(result < 60 * 24 * 30 * 12) {
      return parseInt(result / 60 / 24 / 30) + '月' + after;
    } else if(result < 60 * 24 * 30 * 12 * 100) {
      return parseInt(result / 60 / 24 / 30 / 12) + '年' + after;
    }
  },

  /** 深拷贝 */
  deepClone(data) {
    return JSON.parse(JSON.stringify(data));
  },
  // 处理可见crm企微码账号
  crmEditAccount(partyId) {
    return CRM_EDIT_LIST.includes(partyId);
  },
  // 色彩选择
  colorRgb(color) {
    // 十六进制颜色值的正则表达式
    let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    let sColor = color.toLowerCase();
    if(sColor && reg.test(sColor)) {
      if(sColor.length === 4) {
        let sColorNew = '#';
        for(let i = 1; i < 4; i += 1) {
          sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
        }
        sColor = sColorNew;
      }
      // 处理六位的颜色值
      let sColorChange = [];
      for(let i = 1; i < 7; i += 2) {
        sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2), 16));
      }
      return sColorChange;
    } else {
      return sColor;
    }
  },
  // 色彩值转换成字符串
  colorRgbToString(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  },
  timeFormat(time) {
    let hours,
      minutes,
      seconds;
    let intTime = Math.floor(time);
    hours = Math.floor(intTime / 3600);
    minutes = Math.floor((intTime / 60) % 60);
    seconds = intTime % 60;
    return {
      hours: hours,
      minutes: minutes > 9 ? minutes : '0' + minutes,
      seconds: seconds > 9 ? seconds : '0' + seconds
    };
  },
  /** 格式化时长（秒） */
  durationFormat(second) {
    second = Math.ceil(second);
    let h = Math.floor(second / 3600);
    let m = Math.floor(second / 60) % 60;
    let s = second % 60;
    return [h, m, s].map(r => r.toString().padStart(2, '0')).join(':');
  },
  formatScanDate(second_time) {
    let time = parseInt(second_time, 10) + '秒';
    if(parseInt(second_time, 10) > 60) {
      let second = parseInt(second_time, 10) % 60;
      let min = parseInt(second_time / 60, 10);
      time = min + '分' + second + '秒';
      if(min > 60) {
        min = parseInt(second_time / 60, 10) % 60;
        let hour = parseInt(parseInt(second_time / 60, 10) / 60, 10);
        time = hour + '小时' + min + '分' + second + '秒';
        if(hour > 24) {
          hour = parseInt(parseInt(second_time / 60, 10) / 60, 10) % 24;
          let day = parseInt(
            parseInt(parseInt(second_time / 60, 10) / 60, 10) / 24,
            10
          );
          time = day + '天' + hour + '小时' + min + '分' + second + '秒';
        }
      }
    }
    return time;
  },
  /**
   *1.为处理价格调用，不需要传len参数
   * 2.处理数字位数，必须传len
   */
  toFixed(num = 0, len = 0) {
    let result = '',
      level = 3,
      [tempNum, tail] = `${num}`.split('.'),
      showText = false;
    if(num >= 1000000) {
      showText = true;
      let tempCount = (num / 10000).toFixed(2);
      [tempNum, tail] = `${tempCount}`.split('.');
    }
    const padEnd = (str, len = 2, padString = 0) => {
      str = str.slice(0, len);
      while(str.length < len) {
        str += padString;
      }
      return str;
    };
    if(!len) {
      tail = tail ? padEnd(tail) : '00';
      tempNum = (tempNum || 0).toString();
      while(tempNum.length > level) {
        result = ',' + tempNum.slice(-level) + result;
        tempNum = tempNum.slice(0, tempNum.length - level);
      }
      if(tempNum) {
        result = tempNum + result;
      }
      return `${result}.${tail}${showText ? '万' : ''}`;
    } else {
      if(typeof num !== 'number') {
        num = Number(num);
      }
      return num.toFixed(len);
    }
  },
  /**
   * 社群书书名检测
   */
  showBookName(name = '') {
    return /[《》]/g.test(name) ? name : name ? `《${name}》` : '--';
  },

  /**
   * 删除首尾书名号
   * @param {*} name
   * @returns
   */
  delBookName(name = '') {
    return name.replace(/^(\s|《)+|(\s|》)+$/g, '');
  },

  setTimer(name, func, duration) {
    const flag = sysTimer[name];
    if(flag) {
      this.clearTimer(name);
    }
    sysTimer[name] = window.setTimeout(() => {
      this.clearTimer(name);
      func();
    }, duration);

  },
  clearTimer(name) {
    window.clearTimeout(sysTimer[name]);
    delete sysTimer[name];
  },
  formatChatTime(time) {
    if(!time) return '';
    let now = +new Date(),
      createTime = +new Date(time);
    const nowHour = new Date().getHours();
    const s = Math.floor((now - createTime) / 1000),
      m = Math.floor(s / 60),
      h = Math.floor(m / 60);
      // d = Math.floor(h / 24);
    if(m < 1) {
      return `刚刚`;
    } else if(h < 1) {
      return `${m}分钟前`;
    } else if(h < 24 && (nowHour > h)) {
      return moment(time).format('HH:mm');
    } else if(h < 48) {
      return `昨天 ${moment(time).format('HH:mm')}`;
    } else {
      return moment(time).format('MM/DD');
    }
  },
  getTimeDifference(startTime) {
    let timeDesc = '';
    let startDate = new Date(startTime);
    let endDate = new Date();
    let differenceTime = endDate.getTime() - startDate.getTime();
    //计算相差的天数
    let days = Math.floor(differenceTime / (24 * 3600 * 1000));
    let leave1 = differenceTime % (24 * 3600 * 1000);         //整天过后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000));
    let leave2 = leave1 % (3600 * 1000);  //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
    if((days <= 0) && hours <= 0) {
      if(minutes < 1 || days < 0) {
        timeDesc = '刚刚';
      } else {
        timeDesc = `${minutes}分钟前`;
      }
    } else if(days === 0 && (hours > 0 && hours < 24)) {
      timeDesc = `${dayjs(startTime).format('HH:mm')}`;
    } else if(days >= 1 && days <= 2) {
      timeDesc = '昨天';
    } else if(days > 2 && days <= 7) {
      timeDesc = `${days}天前`;
    } else if(days > 7 && days < 365) {
      timeDesc = dayjs(startTime).format('MM/DD');
    } else if(days >= 365) {
      timeDesc = dayjs(startTime).format('YYYY/MM/DD');
    }
    return timeDesc;
  },
  /** microsoft预览office文件 */
  previewOfficeByMS(fileUrl) {
    // https://www.microsoft.com/en-us/microsoft-365/blog/2013/04/10/office-web-viewer-view-office-documents-in-a-browser/
    window.open('//view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileUrl));
  },
  /**
   * markdown文本格式清除
   * @param {string} mdText MarkDown格式文本
   * @returns {promise}
   */
  async markdownToPureText(mdText) {
    if(!mdText) return '';
    try {
      const plainText = removeMd(mdText, {
        stripListLeaders: false
      });
      return Promise.resolve(plainText);
    } catch(err) {
      console.warn(err);
      return '';
    }
  },
  syncMarkdownToPureText(mdText) {
    if(!mdText) return '';
    try {
      return removeMd(mdText, { stripListLeaders: false });
    } catch(err) {
      console.warn(err);
      return mdText;
    }
  },
  /**
   * 清除转义'\'
   * @param {string} text
   * @returns
   */
  removeSlashMark(text) {
    return text.replace(/\\(.)/g, (m, p1) => {
      return p1;
    });
  },
  /** 加载字体 */
  loadFont(name, url, cb) {
    if(![...document.fonts].map(item => item.family).includes(name)) {
      const font = new window.FontFace(name, `url(${url})`);
      document.fonts.add(font);
      font.load().then(info => {
        cb && cb(true);
      }).catch(err => {
        cb && cb(false);
        console.log(err);
      });
    }
  },
  // 获取一个时间段内的所有时间序列
  enumerateDaysBetweenDates(startDate, endDate) {
    let daysList = [];
    const start = moment(startDate);
    const end = moment(endDate);
    const day = end.diff(start, 'days');
    daysList.push(start.format('YYYY-MM-DD'));
    for(let i = 1; i <= day; i++) {
      daysList.push(start.add(1, 'days').format('YYYY-MM-DD'));
    }
    return daysList;
  },
  // 获取源文件url
  getOriginUrl(url) {
    return url.replace('//oss.', '//file.');
  },
  /** 获取网页站点图标 */
  getWebIcon(url) {
    try {
      const u = new URL(url);
      return u.origin + '/favicon.ico';
    } catch(err) {
      console.log('URL err', err);
      return '';
    }
  },
  checkSupportWebP() {
    return true;
    // (
    //   document
    //     .createElement('canvas')
    //     .toDataURL('image/webp')
    //     .indexOf('data:image/webp') === 0
    // );
  },
  /**
   * oss图片
   * https://support.huaweicloud.com/usermanual-obs/obs_01_0430.html
   * https://help.aliyun.com/document_detail/50039.html?spm=5176.doc31848.6.750.BrFGlG
   * @param {string} src
   * @param {number} ratio 图片缩放比例 `(0-1]` 0不处理缩放
   * @param {object} effect 图片处理效果例如 `{resize:'w_100,h_100'}`
   * @returns {string}
   */
  getImg(src, ratio = 0.6, effect = {}) {
    const handleOssImg = (url, ratio, effects) => {
      const TYPE = 'image',
        EFFECTSKEY = 'x-oss-process';
      let baseUrl = url;
      let queryStr = '';
      let query = {};
      let queryIndex = url.indexOf('?');
      let effectsStr = '';
      if(queryIndex > -1) {
        baseUrl = url.slice(0, queryIndex);
        queryStr = url.slice(queryIndex + 1);
      }
      if(queryStr) {
        query = Utils.params2json(queryStr);
      }
      if(query[EFFECTSKEY]) {
        effectsStr = query[EFFECTSKEY];
      }
      const str2actions = (actionsStr = effectsStr) => {
        let actionsArr = actionsStr.split('/');
        let actions = {};
        if(actionsArr.length > 1) {
          actionsArr.slice(1).forEach((item = '') => {
            let keyIndex = item.indexOf(',');
            let key = item.slice(0, keyIndex),
              value = item.slice(keyIndex + 1);
            actions[key] = value;
          });
        }
        return actions;
      };
      const actions2str = (actions = {}) => {
        let str = '';
        for(let key in actions) {
          str += `/${key},${actions[key]}`;
        }
        return str;
      };

      if(ratio > 0) {
        ratio = ratio >= 1 ? 1 : ratio;
        Object.assign(effects, {
          resize: effects.resize || `p_${Math.round(ratio * 100)}`
        });
      }
      // webp
      if(Utils.checkSupportWebP()) {
        Object.assign(effects, {
          format: 'webp'
        });
      }
      if(Object.keys(effects).length) {
        let resultActions = `${TYPE}${actions2str(Object.assign({}, str2actions(), effects))}`;
        if(effectsStr) {
          let reg = new RegExp(effectsStr, 'g');
          return url.replace(reg, resultActions);
        } else {
          return `${baseUrl}?${Utils.json2params(Object.assign({}, query, {
            [EFFECTSKEY]: resultActions
          }))}`;
        }
      } else {
        return url;
      }
    };
    // 华为云gif => webp 异常
    if(src && src.indexOf('/oss/') > -1 && (!/\.(gif)$/.test(src))) {
      return handleOssImg(src, ratio, effect);
    } else {
      return src;
    }
  },

  //校验是否有数字人权限
  checkHasAiPermission(item = {}) {
    // let data = {
    //   ...item
    // };
    // let endTime =  new Date(item.endTime || 0).getTime();
    // let startTime =  new Date(item.startTime || 0).getTime();
    // let nowTime = new Date().getTime();
    // if(item?.code === 'knowledgebase') {
    //   data.status = 1;
    //   data.hiddenTime = true;
    // } else {
    // // 0 未设置时间
    // // 1 正常使用
    // // -1 过期
    // // -2 未到使用时间
    //   data.status = !item.startTime ? 0 : (endTime > nowTime && nowTime > startTime) ? 1 : nowTime > endTime ? -1 : -2;
    //   if(data.status === 1 && (endTime - nowTime) > (1000 * 60 * 60 * 24 * 7)) {
    //     data.hiddenTime = true;
    //   }
    // }
    // return data;
    return {
      ...item,
      status: 1,
      hiddenTime: true
    };
  },
  getMonthSub() {
    const nowDay = Number(`${moment().format('DD')}`);
    const monthSub  = nowDay >= MONTH_SUB_SEPARATOR_DAY ? 1 : 2;
    return monthSub;
  },
  // 获取某某年截至今年的所有年份
  getYearsToNow(startYear = 2022) {
    //获取到从那一年开始
    let smallYears = startYear;
    //获取当前时间
    let date = new Date();
    let nowYears = date.getFullYear();
    let Years = nowYears - smallYears;
    let arrYear = [];
    for(let i = 0; i <= Years; i++) {
      arrYear.push(nowYears--);
    }
    return arrYear;
  },
  /**
   * 获取分页数据的加载状态
   */
  getPageLoadStatus(pageBean = EMPTY_PAGE_BEAN) {
    const empty = pageBean.totalCount === 0;
    return {
      empty,
      loading: pageBean.recordList.length === 0 && !empty,
      allLoaded: pageBean.recordList.length === pageBean.totalCount
    };
  },
  getDeviceId() {
    const key = 'DEVICE_ID_WS';
    let deviceId = localStorage.getItem(key);
    if(!deviceId) {
      deviceId = `${Date.now()}${uuid()}`;
      localStorage.setItem(key, deviceId);
    }
    return deviceId;
  },
  /**
   * 文件名长度超出时，中间用... 如： `海底两万里解...pdf`
   * @param {string} text 文件名，如：`海底两万里解析大全.pdf`
   * @param {string} ext 后缀，如：`pdf`
   * @param {number} maxLen 最大长度(中文按2个字符算)
   * @returns fileName
   */
  getShortFileName(text = '', ext, maxLen = 20) {
    ext = ext.replace(/^\./g, '');
    text = text.replace(new RegExp(`.${ext}$`, 'i'), '');
    // 判断字符是不是中文、标点
    const reg =  /[a-zA-Z0-9,.!@#$%^&*()\+-=\[\]\{}\|]/;
    const textMaxLen = maxLen - ext.length - 1;
    const textMaxLen1 = maxLen - ext.length - 4;
    let len = 0;
    let arr = [];
    // 遍历字符串
    for(let i = 0; i <= text.length; i++) {
      if(len > textMaxLen) {
        // textMaxLen
        let j = i;
        let cLen = len;
        for(; j > 0; j--) {
          cLen -= (arr[j] || 0);
          if(cLen <= textMaxLen1) {
            break;
          }
        }
        const shortText = text.substring(0, j).trim() + '....' + ext;
        return shortText;
      }
      // 超过了最大长度
      if(i === text.length) break;
      // 如果是中文
      if(!reg.test(text[i])) {
        // 中文字符长度为2
        len += 2;
        arr.push(2);
      } else {
        len += 1;
        arr.push(1);
      }
    }
    return text + '.' + ext;
  },
  // 节流函数
  throttle(func, wait = 1000, options = { leading: true, trailing: true }) {
    let timeout = null;
    let lastArgs = null;
    let lastThis = null;
    let lastCallTime = 0;

    const invokeFunc = () => {
      func.apply(lastThis, lastArgs);
      lastArgs = lastThis = null;
      lastCallTime = Date.now();
    };

    const timerExpired = () => {
      timeout = null;
      if(options.trailing && lastArgs) {
        invokeFunc();
      }
    };

    const throttled =  (...args) => {
      const now = Date.now();
      const remaining = wait - (now - lastCallTime);

      lastArgs = args;
      lastThis = this;

      if(remaining <= 0 || remaining > wait) {
        if(timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        invokeFunc();
      } else if(!timeout) {
        timeout = setTimeout(timerExpired, remaining);
      }
    };

    throttled.cancel = () => {
      if(timeout) {
        clearTimeout(timeout);
      }
      timeout = lastArgs = lastThis = null;
    };

    return throttled;
  },
  //深拷贝 包含function，data等对象
  deepCloneObj(obj) {
    if(obj === null || typeof obj !== 'object') {
      return obj;
    }

    if(obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if(obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = Utils.deepCloneObj(item);
        return arr;
      }, []);
    }

    if(obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = Utils.deepCloneObj(obj[key]);
        return newObj;
      }, {});
    }
  },
  // 多维数组铺平
  flattenArray(arr) {
    const flattened = [];
    (function flatten(array) {
      array.forEach(item => {
        if(Array.isArray(item)) {
          flatten(item);
        } else {
          if(item !== null) flattened.push(item);
        }
      });
    })(arr);
    return flattened;
  },
  // 将url参数转化为对象
  urlParamsToObject(url) {
    url = new URL(url);
    const params = new URLSearchParams(url.search);
    const paramObj = {};
    for(let [key, value] of params) {
      paramObj[key] = decodeURIComponent(value);
    }
    return paramObj;
  }
};

export default Utils;

// 小睿伴读小程序
const BAN_DU_APP = [
  'PHOTOGRAPH',  //
  'AI_TIKU',
  'APPLET_CLOCK',
  'APPLET_QA',
  'APPLET_WORD',
  'CALCULATOR',
  'FUNCTIONAL_DIGRAPH',
  'BRAIN_GRAPH',
  'EXAM_PAPER',
  'ANCIENT_WORD',
  'IDIOM',
  'POETRY',
  'ARITHMETIC',
  'CORRECTION',
  'ANSWER_ANALYSIS'
];

// 鲁班工程-作品重定向到rays-apps项目
const NEW_PRODUCT_TYPES = [
  {
    typeCode: 'SPECIALITY_TEST_EVALUATION', // 作品唯一CODE
    prefix: 'evaluation', // 链接前缀
    suffix: 'main' // 首页链接后缀
  },
  {
    typeCode: 'EN_ORAL_EVALUATION',
    prefix: 'speak',
    suffix: 'info'
  }
];

// 以下编辑可见crm的企微码
const CRM_EDIT_LIST = [
  1000028641,
  1000024805,
  1000028013,
  1000028700,
  1000019601,
  1000010311,
  1000004359,
  1002367,
  1000026825,
  1000006647,
  1000006652,
  1000013484,
  1000013480,
  1000013451,
  1000008133,
  1004209,
  1000011379
];

const MONTH_SUB_SEPARATOR_DAY = 17;
