/*
 * @Author: Paco
 * @Date: 2018-11-23 20:39:54
 * @LastEditTIme: Paco
 * @Description:
 */
import Axios from 'axios';
import shortid from '@wtdc/shortid';
import Sentry from '@/sentry';

import { message } from '@/components/AntD';
import { BASE_API_ROOT } from '@/config';
import * as Cookie from '@/utils/cookie';
import PAGE from '@/constants/page';
import Utils from '@/utils/utils';

const axios = Axios.create();

axios.defaults.baseURL = BASE_API_ROOT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.token = Cookie.getCookie();

// get 请求特殊字符[、]接口查询异常
const encodeReplace = (data) => {
  for(let key in data) {
    // Check if the value is a string
    if(typeof data[key] === 'string') {
      data[key] = data[key].replace(/\[|]/g, '');
    }
  }
  return data;
};
/**
 * 添加ie缓存清理
 * @params config
 * @returns {*}
 */
const addUidForIe = function(config) {
  if(config.method === 'get') {
    if(!config.params) {
      config.params = {};
    }
    config.params = encodeReplace(config.params);
    config.params.t = shortid();
  }
  return config;
};

/**
 * 添加token
 * @params config
 */
const addToken = (config) => {
  config.headers = config.headers || {};
  const cookie = Cookie.getCookie();
  if(cookie) {
    config.headers.token = cookie;
  }
  return config;
};

/**
 * 格式化sentry上报的数据格式
 * @param res
 * @returns {{responseURL, responseType, cookie: *, response, responseText, statusText, responseXML, status}}
 */
function formatExtra(res = {}) {
  const { response, responseText, responseType, responseURL, responseXML, status, statusText } = res;
  return {
    response,
    responseText,
    responseType,
    responseURL,
    responseXML,
    status,
    statusText,
    cookie: Cookie.getCookie()
  };
}

const formatResponse = (response) => {
  const { data = {}, config = {}, request = {}} = response;
  if(data.errCode === 0) {
    if(data.encrypt) {
      const d = data.data;
      let decodeData = Utils.decrypt(d, '~dcg$rays@2022^&');
      if(Utils.isJSONString(decodeData, true)) {
        decodeData = JSON.parse(`${decodeData}`);
      }
      return decodeData;
    }
    return data.data;
  } else {
    if(data.message) {
      message.error(data.message);
    }
    // 业务错误 sentry埋点
    Sentry.captureMessage(`HTTP ERROR: ${data.message}`, {
      extra: {
        ...formatExtra(request),
        data: config?.data || {}
      }
    });
    // throw new Error(`${CAPTURED}: ${data.message}`);
    return Promise.reject(data);
  }
};

const ERROR_CODE = {
  SERVER_ERROR: 500,
  NETWORK_ERROR: 502,
  AUTHORIZE_ERROR: 401
};

const formatAjaxError = (fetchInfo) => {
  const { response = {}} = fetchInfo;
  const { status, data, request, config } = response;
  const error = {
    message: data || '服务器内部错误，请稍后重试！',
    status: status
  };
    // 服务器错误 sentry埋点
  Sentry.captureMessage(`HTTP ERROR: ${data}`, {
    extra: {
      ...formatExtra(request),
      data: config?.data || {}
    }
  });
  if(typeof data === 'object' && typeof data.message === 'string') {
    error.message = data.message;
  }
  if(typeof data === 'object' && typeof data.error === 'string') {
    error.message = data.error;
  }
  if(status === ERROR_CODE.NETWORK_ERROR) {
    error.message = '网络问题，请稍后重试！';
  } else if(status === ERROR_CODE.AUTHORIZE_ERROR) {
    sessionStorage.setItem('cbPath', window.location.pathname + window.location.search);
    Cookie.removeCookie();
    error.message = '登录已过期，请重新登录！';
    window.setTimeout(() => {
      window.location.href = window.location.origin + PAGE.LOGIN;
    }, 500);
  }
  if(error.message) {
    if(error.message.indexOf('!DOCTYPE html') > -1) {
      //message.error('请求服务器错误：' + fetchInfo?.request?.responseURL);
    } else message.error(error.message);
  }
  return Promise.reject(error);
};

axios.interceptors.request.use(addUidForIe, console.error);
axios.interceptors.request.use(addToken, console.error);

//Add a response interceptor
axios.interceptors.response.use(formatResponse, formatAjaxError);

export default function axiosServices(root, api) {
  if(api) {
    root = window.location.origin + api + root;
  }
  return (method, route) => (data) => {
    const payload = method === 'get' ? { params: data } : data;
    return axios[method](
      `${root}/${route}`,
      payload
    );
  };
}
