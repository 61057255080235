/* eslint-disable no-unused-vars */

// **功能色**
const primary_color = '#5156BE', // Normal
  primary_color_light = '#F2F3FF', // Light
  primary_color_disabled = '#B1B4ED', // Disabled
  primary_color_hover = '#666CEB', // Hover
  primary_color_active = '#414598'; // Click

// 主色 4%
const primary_color_4 = '#f8f8fd';
// 主色 8%
const primary_color_8 = '#f1f2fa';

const error_color = '#FA5652',
  error_color_light = '#FFF2F2', // Light
  error_color_focus = '#FFD8D2', // Focus
  error_color_hover = '#E64F4C', // Hover
  error_color_active = '#AD352F'; // Click

const warning_color = '#FFA100',
  warning_color_light = '#FFF1E9', // Light
  warning_color_focus = '#FFD9C2', // FocuS
  warning_color_hover = '#FA9550', // Hover
  warning_color_active = '#BE5A00'; // Click

const success_color = '#2AB57D',
  success_color_light = '#E3F9E9', // Light
  success_color_focus = '#C6F3D7', // FocuS
  success_color_hover = '#56C08D', // Hover
  success_color_active = '#008858'; // Click

// **方案&图标色**
const font_gy_1 = 'rgba(0, 0, 0, 0.9)'; // Primary
const font_gy_2 = 'rgba(0, 0, 0, 0.6)'; // Secondary
const font_gy_3 = 'rgba(0, 0, 0, 0.4)'; // Placeholder
const font_gy_4 = 'rgba(0, 0, 0, 0.26)'; // Disabled
const font_wh_1 = 'rgba(255, 255, 255, 0.9)'; // Anti
const font_wh_2 = 'rgba(255, 255, 255, 0.55)';
const font_wh_3 = 'rgba(255, 255, 255, 0.35)';
const font_wh_4 = 'rgba(255, 255, 255, 0.22)';

// 中性色板
const
  neutral_color_1 = '#DCDCDC', // 中性色1：#DCDCDC（滚动条、输入框边框色、不可用色）
  neutral_color_2 = '#EEEEEE', // 中性色2：#EEEEEE（页面容器、表格边框）
  neutral_color_3 = '#F3F3F3', // 中性色3：#F3F3F3（下拉筛选等Hover状态）
  neutral_color_4 = '#F9F9F9', // 中性色4：#F9F9F9（大面积使用的背景色）
  neutral_color_5 = '#F5F7FA'; // 中性色5：#F5F7FA（表格表头）

const White1 = '#FFFFFF';

const token = {
  colorPrimary: primary_color,
  colorPrimaryHover: primary_color_hover,
  colorPrimaryActive: primary_color_active,
  colorInfo: primary_color,
  colorInfoBg: primary_color_light,
  colorInfoBorder: primary_color_disabled,
  colorSuccess: success_color,
  colorSuccessBgHover: success_color_hover,
  colorSuccessActive: success_color_active,
  colorSuccessBg: success_color_light,
  colorSuccessBorder: success_color_focus,
  colorWarning: warning_color,
  colorWarningHover: warning_color_hover,
  colorWarningActive: warning_color_active,
  colorWarningBg: warning_color_light,
  colorWarningBorder: warning_color_focus,
  colorError: error_color,
  colorErrorHover: error_color_hover,
  colorErrorActive: error_color_active,
  colorErrorBg: error_color_light,
  colorErrorBorder: error_color_focus,
  // colorHighlight: '',
  colorWhite: font_wh_1,

  colorPrimaryBg: White1,
  colorPrimaryBgHover: neutral_color_3,
  colorPrimaryBorder: neutral_color_1,
  colorPrimaryBorderHover: neutral_color_3,
  // colorPrimaryHover
  // colorPrimary
  // colorPrimaryActive
  colorPrimaryTextHover: font_gy_2, // ?
  colorPrimaryText: font_gy_1,
  colorPrimaryTextActive: font_gy_2, // ?
  // colorBgBase: neutral_color_2,
  colorBgContainer: White1,
  // colorBgElevated:
  // colorSplit
  fontFamily: 'San Francisco UI, Helvetica Neue, Arial, DIN Alternate, PingFang SC, Hiragino Sans GB, Micrisoft YaHei', //
  colorText: font_gy_1,
  colorTextSecondary: font_gy_2,
  borderRadius: 4,
  colorBorder: neutral_color_1,

  colorTextHeading: font_gy_1,
  colorTextDisabled: font_gy_4,
  colorBgContainerDisabled: '#f9f9f9',
  boxShadow: '0 1px 10px rgba(0, 0, 0, 5%), 0 4px 5px rgba(0, 0, 0, 8%), 0 2px 4px -1px rgba(0, 0, 0, 12%)',
  boxShadowSecondary: '0 3px 14px 2px rgba(0, 0, 0, 5%), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%)',

  colorAlertWarning: '#F6A516',
  colorAlertBgWarning: 'rgba(246, 165, 22, 0.08)'
};

// antd 5.x
// https://ant-design.antgroup.com/docs/react/customize-theme#design-token
// https://ant-design.antgroup.com/docs/react/migrate-less-variables

/** @type {import('antd').ThemeConfig}*/
const theme = {
  token,
  components: {
    Button: {
      contentFontSizeSM: 12,
      onlyIconSizeSM: 12,
      colorLinkHover: primary_color_hover
    },
    Tooltip: {
      // tooltipBg: '',
      colorBgSpotlight: 'rgba(0, 0, 0, 0.75)'
    },
    Switch: {
      handleBg: '#fff'
    },
    Select: {
      optionSelectedFontWeight: 400,
      optionActiveBg: neutral_color_3,
      optionSelectedColor: primary_color,
      optionSelectedBg: primary_color_8
    }
  },
  hashed: false
};

export default theme;
