
export const isDevelopment = process.env.NODE_ENV !== 'production';

// const hostIP = isDevelopment ? 'localhost' : window.location.hostname; //STEP2
// const publicDomain = isDevelopment ? 'rays7.raysgo.com' : hostIP;
const protocol = isDevelopment ? 'http:' : window.location.protocol;
// const domainArr = ['raysgo.com', '5rs.me'];
// const envArr = ['TEST', 'PRO'];
// const envIndex = domainArr.findIndex(v => publicDomain.indexOf(v) > -1); // 0测试环境，1生产环境;

const domainArr = ['voyagechannel-dev.raysgo.com', 'voyagechannel.raysgo.com', 'manage.bookvoyage.cn'];
const envArr = ['DEV', 'TEST', 'PRO'];
const envIndex = isDevelopment ? 0 : domainArr.findIndex(v => v === window.location.hostname); // 0开发环境 1测试环境 2生产环境;

export const BASE_API_ROOT = window.location.origin + (isDevelopment ? '/api' : '');

// 系统角色
const systemCode = 'book_voyage_channel';

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  // publicDomain,
  systemCode,
  industryCaseId: systemId,
  systemNameText: '运营管理系统',
  COOKIE: `${systemCode}_token`,
  env: envArr[envIndex],
  iconfontCSS: '//at.alicdn.com/t/c/font_3953416_ibzq6ifi6j.css',
  iconfontURL: [
    '//at.alicdn.com/t/c/font_3953416_ibzq6ifi6j.js'
  ],     // 字体图标库链接
  protocol
};

const clientDomainArr = ['voyageadviser-dev.raysgo.com', 'voyageadviser.raysgo.com', 'client.bookvoyage.cn'];
/** 编辑端 origin */
export const CLIENT_ORIGIN = 'https://' + clientDomainArr[envIndex];

export default config;
