import axiosServices from './request';

const DemandService = axiosServices('demand/v1.0');

export default {
  /** 登录 */
  login: DemandService('post', 'operatorAccount/login'),
  /** 修改登录密码 */
  changePassword: DemandService('post', 'operatorAccount/changePassword'),
  /** 忘记密码找回账号 */
  retrieve: DemandService('post', 'operatorAccount/retrieve'),
  /** 获取用户信息 */
  getUserInfo: DemandService('get', 'operatorAccount/info'),
  /** 获取图书分类 */
  bookCatagoryList: DemandService('get', 'userBookMarketing/bookCategoryList'),
  /** 查看留资记录列表 */
  consultationList: DemandService('get', 'operatorAccount/consultationList'),
  /** 处理留资记录 */
  dealConsultation: DemandService('post', 'operatorAccount/dealConsultation'),
  /** 校验图书名称是否已存在 */
  checkDemand: DemandService('post', 'operatorBookMarketing/checkDemand'),
  /** 运营查看图书营销需求统计数据 */
  demandStatistics: DemandService('get', 'operatorBookMarketing/demandStatistics'),
  /** 渠道 */
  channelEntranceList: DemandService('get', 'operatorEntrance/channelEntranceList'),
  /** 物料图书类别 */
  demandBookCategoryList: DemandService('get', 'operatorBookMarketing/bookCategoryList'),
  /** 运营获取图书营销需求列表 */
  demandList: DemandService('get', 'operatorBookMarketing/demandList'),
  /** 运营获取图书营销需求额外物料列表 */
  demandExtraMaterialList: DemandService('get', 'operatorBookMarketing/demandExtraMaterialList'),
  /** 运营查看图书营销需求详情 */
  queryDemandDetail: DemandService('get', 'operatorBookMarketing/demandDetail'),
  /** 根据手机号查询编辑端账号信息 */
  queryByUserPhone: DemandService('get', 'operatorAccount/queryByUserPhone'),
  /** 运营创建图书营销需求 */
  launchDemand: DemandService('post', 'operatorBookMarketing/launchDemand'),
  // 运营查看图书详细信息
  getBookDetailInfo: DemandService('post', 'operatorBookMarketing/getBookDetailInfo'),
  // 根据ISBN填充详细信息
  queryBookInfoByIsbn: DemandService('get', 'operatorBookMarketing/queryBookInfo'),
  // 根据图书征订单提取图书详细信息
  extractBookDetailInfo: DemandService('post', 'operatorBookMarketing/extractBookDetailInfo'),
  fetchExtractedResult: DemandService('get', 'operatorBookMarketing/fetchExtractedResult'),
  /** 运营修改图书征订单、图书样张 */
  updateMaterial: DemandService('post', 'operatorBookMarketing/updateMaterial'),
  /** 运营修改图书详细信息 */
  updateBookDetailInfo: DemandService('post', 'operatorBookMarketing/updateBookDetailInfo'),
  /** 删除运营修改图书物料 */
  deleteExtraMaterials: DemandService('post', 'operatorBookMarketing/deleteExtraMaterials'),
  /** 运营上传图书物料 */
  demandUploadExtraMaterials: DemandService('post', 'operatorBookMarketing/uploadExtraMaterials'),
  /** 替换已上传图书物料 */
  demandReplaceExtraMaterials: DemandService('post', 'operatorBookMarketing/replaceExtraMaterial'),
  /** 运营上传交付物 */
  demandUploadDelivery: DemandService('post', 'operatorBookMarketing/uploadDelivery'),
  /** 运营生成图书营销亮点  */
  generateBookMarketingHighlights: DemandService('post', 'operatorBookMarketing/generateBookMarketingHighlights'),
  getBookMarketingHighlights: DemandService('get', 'operatorBookMarketing/getBookMarketingHighlights'),
  /** 资料检查  */
  checkDemandRequirements: DemandService('get', 'operatorBookMarketing/checkDemandRequirements'),
  /** 开始执行图书扫描任务 */
  demandExecuteTask: DemandService('get', 'operatorBookMarketing/executeTask'),
  /** 运营提交图书营销亮点并开启生成 */
  startGenerating: DemandService('post', 'operatorBookMarketing/startGenerating'),

  // 营销结果物列表
  getGeneratedResult: DemandService('get', 'operatorBookMarketing/getGeneratedResult'),
  /**使用交付物 */
  useAiOutputForDelivery: DemandService('post', 'operatorBookMarketing/useAiOutputForDelivery'),
  /** AI 结果物重新生成 */
  regenerateResult: DemandService('post', 'operatorBookMarketing/regenerateResult'),
  /** 导出AI结果物  */
  exportAdjustedResult: DemandService('post', 'operatorBookMarketing/exportAdjustedResult'),
  /** 运营导出图书营销亮点 */
  exportBookMarketingHighlights: DemandService('post', 'operatorBookMarketing/exportBookMarketingHighlights'),
  /** 应用结果  */
  applyAdjustedResult: DemandService('post', 'operatorBookMarketing/applyAdjustedResult'),

  /** （渠道矩阵）列表查询 */
  channelUserGetList: DemandService('get', 'mediaOperation/channelUser/getList'),
  /** （渠道矩阵）新增账号 */
  channelUserInsert: DemandService('post', 'mediaOperation/channelUser/insert'),
  /** （渠道矩阵）删除账号信息 */
  channelUserSoftDelete: DemandService('get', 'mediaOperation/channelUser/softDeleteById'),
  /** （渠道矩阵）更新账号信息 */
  channelUserUpdate: DemandService('post', 'mediaOperation/channelUser/update'),
  /** 跟据用户id获取待发布列表 */
  publishRecordList: DemandService('get', 'mediaOperation/publishRecord/getList'),
  /** 跟据记录id修改发布状态 */
  updatePublishStatus: DemandService('post', 'mediaOperation/publishRecord/updatePublishStatus'),
  /** 待发布列表更新视频标题 */
  updateById4Publish: DemandService('post', 'mediaOperation/publishRecord/updateById'),
  /** 待制作视频 */
  getVideoList: DemandService('get', 'video/page'),
  /** 人工上传 */
  uploadVideo: DemandService('post', 'video/updateVideo'),
  /** 更新视频标题 */
  updateVideoTitle: DemandService('get', 'video/updateVideoTitle'),
  /** 查看资料 */
  checkResource: DemandService('get', 'video/checkResource'),
  /** 查询图书补充信息 */
  queryResourceById: DemandService('get', 'video/queryResourceById'),
  /** 查询营销方案 */
  queryById4marketerScheme: DemandService('get', 'marketerScheme/queryById'),
  /** 图书数据导入 */
  importBookDataExcel: DemandService('post', 'bookData/importBookDataExcel'),
  /** 客户信息-待上传列表 */
  listPageUploadBook: DemandService('get', 'bookData/listPageUploadBook'),
  /** 客户信息-待上传列表-数据导出 */
  exportBookData: DemandService('get', 'bookData/exportBookData'),
  /** 工作台首页统计数据 */
  statistics: DemandService('get', 'operatorWorkstation/statistics'),
  /** 工作台数据统计列表 */
  dataList4Workstation: DemandService('post', 'operatorWorkstation/dataList'),
  /** 工作台获取编辑基础信息 */
  getUserBaseInfo: DemandService('get', 'operatorWorkstation/getUserBaseInfo'),
  /** 工作台获取编辑端登录的JWT */
  getUserJwt: DemandService('get', 'operatorWorkstation/getUserJwt'),

  /** 数据洞察-销售总览 */
  getBookDataOverview: DemandService('get', 'bookData/getBookDataOverviewByOperator'),
  /** 数据洞察-销售渠道分布 */
  getBookDataSaleTerminalDistribute: DemandService('get', 'bookData/getBookDataSaleTerminalDistributeByOperator'),
  /** 数据洞察-销售方式分布 */
  getBookDataSaleMethodDistribute: DemandService('get', 'bookData/getBookDataSaleMethodDistributeByOperator'),
  /** 数据洞察-数据趋势 */
  getBookDataTrend: DemandService('get', 'bookData/getBookDataTrendByOperator'),
  /** 数据洞察-数据明细 */
  getBookDataList: DemandService('get', 'bookData/getBookDataList'),
  /** 数据洞察详情-数据明细销售趋势 */
  getBookDataSaleTrend: DemandService('get', 'bookData/getBookDataSaleTrendByOperator'),
  /** 数据洞察详情-数据明细销售趋势导出 */
  exportBookDataSaleTrend: DemandService('get', 'bookData/exportBookDataSaleTrendByOperator'),
  /** 数据洞察详情-图书信息 */
  getBookInfo: DemandService('get', 'bookData/getBookInfo'),

  /** 精细化创作视频 start */
  /**搜索书名 */
  qureyBookinfoByBookName: DemandService('post', 'marketerScheme/qureyBookinfoByBookName'),
  /** 搜索卖点 */
  sellingPoint: DemandService('get', 'video/sellingPoint'),
  /** 根据书匹配热点信息 */
  getHotInfoByBookName: DemandService('post', 'marketerScheme/getHotInfoByBookName'),
  /** 根据分镜创建分镜 */
  buildVideoStructure: DemandService('post', 'video/buildVideoStructure'),
  /** 导出分镜素材 */
  exportResource: DemandService('get', 'video/exportResource'),
  /** 查询导出资源url */
  queryExportUrl: DemandService('get', 'video/queryExportUrl'),
  /** 专场list */
  animationEffect: DemandService('get', 'video/animationEffect'),
  /** 根据分镜创建资源 */
  buildVideoResource: DemandService('post', 'video/buildVideoResource'),
  /** 获取发音人 */
  informant: DemandService('get', 'video/informant'),
  /** 获取背景音乐 */
  backgroundMusicList: DemandService('get', 'tts/backgroundMusicList'),
  /** 查询分镜 */
  queryVideoResource: DemandService('get', 'video/queryVideoResource'),
  /** 更新分镜 */
  updateVideoResource: DemandService('post', 'video/updateVideoResource'),
  /** 换一换视频 */
  random: DemandService('post', 'video/random'),
  /** 根据资源生成视频 */
  confirmResource: DemandService('post', 'video/confirmResource'),
  /**重新生成视频 */
  operatorReTry: DemandService('get', 'video/operatorReTry'),
  /**生成视频并交付*/
  submitVideo: DemandService('get', 'video/submitVideo'),
  /**通过描述获取keyword*/
  extractSearchKeyword: DemandService('get', 'video/extractSearchKeyword')
};
