import React from 'react';
import { Switch } from 'react-router-dom';
import dayjs from 'dayjs';

import theme from '@/assets/theme/theme';
import { ConfigProvider } from '@/components/AntD';
import uuid from '@/utils/uuid';

import './App.scss';

import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

// Modal.confirm 无法移除 :where 选择器
// https://ant-design.antgroup.com/components/config-provider-cn#configproviderconfig
ConfigProvider.config({
  // 5.13.0+
  holderRender: (children) => (
    <ConfigProvider
      // prefixCls="ant"
      // iconPrefixCls="anticon"
      theme={theme}
    >
      {children}
    </ConfigProvider>
  )
});

export default class App extends React.PureComponent {

  componentDidMount() {
    const ismac = /macintosh|mac os x/i.test(navigator.userAgent);
    if(ismac) {
      document.body.setAttribute('ismac', '1');
    }
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    return (
      <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false} theme={theme}>
        <Switch>
          {this.props.children}
        </Switch>
      </ConfigProvider>
    );
  }
}

if(!window.shortid) {
  window.shortid = uuid;
}
