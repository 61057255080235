import Utils from '@/utils/utils';

const BOOK_VOYAGE_CHANNEL = '/channel';

const MAIN_PAGES = {
  /** 首页 */
  HOME: BOOK_VOYAGE_CHANNEL + '/workbench',
  /** 客户留资 */
  CUSTOMER: BOOK_VOYAGE_CHANNEL + '/customer',
  /** 工作台 */
  WORKBENCH: BOOK_VOYAGE_CHANNEL + '/workbench',
  // /** 智能体 */
  // AGENT: BOOK_VOYAGE_CHANNEL + '/agent',
  /** 营销专家(智能体) */
  EXPERT: BOOK_VOYAGE_CHANNEL + '/expert',
  /** 渠道管理 */
  MANAGEMENT: BOOK_VOYAGE_CHANNEL + '/management',
  /** 书船达人 */
  MEDIA_EXPERT: BOOK_VOYAGE_CHANNEL + '/mediaExpert',
  /** 数据统计 */
  STATISTICS: BOOK_VOYAGE_CHANNEL + '/statistics'
};

export const PAGES = {
  ...MAIN_PAGES,
  // ---- 营销专家(智能体) ----
  EXPERT_AI_AJUST: MAIN_PAGES.EXPERT + '/newmedia',
  EXPERT_IMAGE: MAIN_PAGES.EXPERT + '/image',
  EXPERT_IFRAME: MAIN_PAGES.EXPERT + '/iframe',
  EXPERT_VIDEO: MAIN_PAGES.EXPERT + '/video',
  /** 精细生成视频 */
  EXPERT_CREATE_STEP: MAIN_PAGES.EXPERT + '/video/step',

  // ---- 工作台 ----
  /** 营销物料详情 */
  WORKBENCH_METERIALDETAIL: MAIN_PAGES.WORKBENCH + '/meterialdetail',
  WORKBENCH_METERIALRESULT: MAIN_PAGES.WORKBENCH + '/meterialresult',
  /** 客户详情 */
  WORKBENCH_CUSTOMERDETAIL: MAIN_PAGES.WORKBENCH + '/customerdetail',
  /** 数据看板 */
  WORKBENCH_DATA_DETAIL: MAIN_PAGES.WORKBENCH + '/datadetail',

  LOGIN: '/login'
};

export const experienceIds = [2084618]; // 预览账号特殊处理

// 获取带params路由
export function getPageUrl(page, params) {
  let url = page;
  Object.keys(params)
    .forEach(key => {
      if(url.indexOf(`:${key}`) > -1) {
        url = url.replace(`:${key}`, params[key]);
        delete params[key];
      }
    });
  return `${url}${Utils.formatQuery2QueryStr(params)}`;
}

export default PAGES;
